import Header from "../../header";
import Footer from "../../footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import {
  resetPasswordApi,
  resendOTPApi,
  validateOTPApi,
} from "../../../client/methods";
import { responseToastMsg } from "../../../utils/response-message";
import { useFormik } from "formik";
import { loginSchema } from "../../../utils/schema";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  userLoginThunk,
  userLogoutThunk,
} from "../../../redux/thunk/user.thunk";
import { useQuery } from "../../../hooks/url-params";
import { getCookies } from "../../../utils/cookies";
import { CreatePasswordSchema } from "../../../utils/schema";
import InputOTP from "../../../components/input-otp/index";
import { validatePassword } from "../../../utils/common";
import AppPasswordInput from "../../../components/app-password-input";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const query = useQuery(location.search);
  const redirect = query.get("redirect");

  useEffect(() => {
    if (location.state?.otp_id === undefined) {
      navigate("/", { replace: true });
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState({
    newpassword: false,
    valid_newpassword: false,
    not_same: false,
  });
  const [input, setInput] = useState({
    newpassword: "",
    rnewpassword: "",
  });

  const [otp, setOtp] = useState(true);
  const [otpValue, setOTPValue] = useState("");
  const [reLoading, setReLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [resetToken, setResetToken] = useState();

  const handleSubmit = async () => {
    if (checkValidation()) {
      try {
        setLoading(true);
        const result = await resetPasswordApi({
          password: input.newpassword,
          check: resetToken,
        });

        setLoading(false);
        responseToastMsg(result?.data?.response_code);
        navigate("/signin", { replace: true });
      } catch (err) {
        responseToastMsg(err?.data?.error_code);
        console.log("🚀 ~ file: index.js ~ line 106 ~ handleSignUp ~ err", err);

        setLoading(false);
      }
    }
  };

  const checkValidation = () => {
    let c_validation = { ...validation };

    if (!input.newpassword) {
      c_validation = { ...c_validation, newpassword: true };
    } else {
      if (validatePassword(input.newpassword)) {
        c_validation = { ...c_validation, valid_newpassword: false };
      } else {
        c_validation = { ...c_validation, valid_newpassword: true };
      }
    }

    if (input.newpassword !== input.rnewpassword) {
      c_validation = { ...c_validation, not_same: true };
    } else {
      c_validation = { ...c_validation, not_same: false };
    }

    setValidation(c_validation);

    if (
      !c_validation.newpassword &&
      !c_validation.valid_newpassword &&
      !c_validation.not_same
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleChangeEvent = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    if (e.target.value) {
      setValidation({ ...validation, [e.target.name]: false });
    } else {
      setValidation({ ...validation, [e.target.name]: true });
    }
  };

  const handleKeyPressEvent = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handleVerifyOTP = async () => {
    if (otpValue.length === 6) {
      try {
        setVerifyLoading(true);
        const result = await validateOTPApi({
          otp_id: location.state.otp_id,
          otp: otpValue,
        });

        setResetToken(result?.data?.response?.salt);
        setOtp(false);
        setVerifyLoading(false);
        responseToastMsg(result?.data?.response_code);
      } catch (err) {
        setVerifyLoading(false);
        console.log(
          "🚀 ~ file: index.js ~ line 172 ~ handleVerifyOTP ~ err",
          err
        );
        responseToastMsg(err?.data?.error_code);
        if (err?.data?.error_code === "ER009") {
          navigate("/forgot-password", { replace: true });
        }
      }
    } else {
      responseToastMsg(
        "Please enter the full OTP received through your email."
      );
    }
  };

  const handleResendOTP = async () => {
    if (location.state.otp_id) {
      try {
        setReLoading(true);
        const result = await resendOTPApi(location.state.otp_id);
        setReLoading(false);
        responseToastMsg(result?.data?.response_code);
      } catch (err) {
        console.log(
          "🚀 ~ file: login.js ~ line 172 ~ handleResendOTP ~ err",
          err
        );
        setReLoading(false);
        setOTPValue("");
        responseToastMsg(err?.data?.error_code);
      }
    }
  };

  return (
    <>
      <Header page={"resetPassword"} />
      <div className="login_wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="l_inner">
                <h2 className="mb-4">Reset your password</h2>
                {otp ? (
                  <>
                    <p>OTP have been sent to your email address.</p>
                    <div className="form-group mb-3">
                      <InputOTP
                        onChange={(e) => setOTPValue(e)}
                        value={otpValue}
                      />
                    </div>
                    {validation.valid_email && (
                      <p className="error_text">
                        Please enter a valid email address
                      </p>
                    )}

                    <div className="d-grid gap-2 mt-4">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleVerifyOTP}
                        disabled={verifyLoading}
                      >
                        {verifyLoading ? "Verifying..." : "Verify"}
                      </button>
                    </div>
                    <p className="mt-3">
                      {reLoading ? (
                        "Sending email..."
                      ) : (
                        <>
                          <a href="#" onClick={handleResendOTP}>
                            Click here
                          </a>{" "}
                          to resend the OTP to your email.
                        </>
                      )}
                    </p>

                    <p className="mb-4 text-secondary">
                      Don't forget to check your Spam/Bulk folder too.
                    </p>
                  </>
                ) : (
                  <>
                    <div className="form-group mb-4">
                      <label>New Password</label>
                      <AppPasswordInput
                        className="form-control"
                        name="newpassword"
                        onChange={handleChangeEvent}
                      />

                      {(validation.newpassword ||
                        validation.valid_newpassword) && (
                        <p className="text-danger">
                          Please enter a valid password. Your password should
                          have a minimum of 6 characters, and should include an
                          uppercase letter and a number.
                        </p>
                      )}
                    </div>

                    <div className="form-group mb-4">
                      <label>Re-type New Password</label>
                      <AppPasswordInput
                        className="form-control"
                        name="rnewpassword"
                        onChange={handleChangeEvent}
                      />

                      {validation.not_same && (
                        <p className="text-danger">
                          Please enter a password as same as above
                        </p>
                      )}
                    </div>

                    <div className="d-grid gap-2 mt-4">
                      <button
                        disabled={loading}
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                      >
                        {loading ? "Reseting..." : "Reset Password"}
                      </button>
                    </div>
                  </>
                )}

                <p className="forgot-password text-center mt-4">
                  Back to <Link to="/"> Sign In </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ResetPassword;
