import Header from "../../header";
import Footer from "../../footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import Logo from "../../../images/logo.png";
import { createPasswordApi } from "../../../client/methods";
import { responseToastMsg } from "../../../utils/response-message";
import { useFormik } from "formik";
import { loginSchema } from "../../../utils/schema";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  userLoginThunk,
  userLogoutThunk,
} from "../../../redux/thunk/user.thunk";
import { useQuery } from "../../../hooks/url-params";
import { getCookies } from "../../../utils/cookies";
import { CreatePasswordSchema } from "../../../utils/schema";
import AppPasswordInput from "../../../components/app-password-input";

const CreateNewPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const query = useQuery(location.search);
  const redirect = query.get("redirect");

  console.log(location);

  const init = {
    create_new_password: "",
    create_confirm_password: "",
  };

  const onSubmit = async (values) => {
    const { create_confirm_password } = values;

    try {
      const result = await createPasswordApi({
        id: user.data.user_id,
        password: create_confirm_password,
      });
      responseToastMsg(result.data.response_code);
      dispatch(userLogoutThunk());
    } catch (error) {
      responseToastMsg(error.data.error_code);
    }
  };

  const formik = useFormik({
    initialValues: init,
    validationSchema: CreatePasswordSchema,
    onSubmit,
  });

  return (
    <>
      <Header page={"createPassword"} />
      <div className="login_wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 col-sm-10">
              <div className="l_inner">
                <h2>Create New Password</h2>
                <form onSubmit={formik.handleSubmit}>
                  <div className="form-group mb-4">
                    <label>New Password</label>
                    <AppPasswordInput
                      placeholder="New Password"
                      value={formik.values.create_new_password}
                      name="create_new_password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`form-control ${
                        formik.touched.create_new_password &&
                        (formik.errors.create_new_password
                          ? "is-invalid"
                          : !formik.errors.create_new_password && "is-valid")
                      }`}
                    />

                    {formik.errors.create_new_password &&
                      formik.touched.create_new_password && (
                        <p className="text-danger">
                          {formik.errors.create_new_password}
                        </p>
                      )}
                  </div>
                  <div className="form-group mb-4 password">
                    <label>Confirm Password</label>
                    <AppPasswordInput
                      placeholder="Confirm Password"
                      value={formik.values.create_confirm_password}
                      name="create_confirm_password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`form-control ${
                        formik.touched.create_confirm_password &&
                        (formik.errors.create_confirm_password
                          ? "is-invalid"
                          : !formik.errors.create_confirm_password &&
                            "is-valid")
                      }`}
                    />

                    {formik.errors.create_confirm_password &&
                      formik.touched.create_confirm_password && (
                        <p className="text-danger">
                          {formik.errors.create_confirm_password}
                        </p>
                      )}
                  </div>

                  <div className="text-center">
                    <button className="btn btn-primary" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateNewPassword;
