import React, { useState, useRef, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Logo from "../../images/logo.png";
import SGILogo from "../../images/sgi-logo.png";
import Carousel from "react-bootstrap/Carousel";
import Money from "../../images/skyline.png";
import Target from "../../images/target.png";
import { FaRegFilePdf } from "react-icons/fa";
import { BiBuildingHouse } from "react-icons/bi";
import { Link, useParams, useLocation } from "react-router-dom";
import {
  createInterest,
  getListingByPageId,
  getMultiDocument,
  getMultiProperty,
  logDownloads,
} from "../../client/methods";

import "./style.scss";
import toast from "react-hot-toast";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { numberFormatter, numberFormatterOnly } from "../../utils/common";
import { useFormik } from "formik";
import { interestSchema } from "../../utils/schema";
import { responseToastMsg } from "../../utils/response-message";
import { useSelector } from "react-redux";
import People from "../../components/people-sgi/index";
import Disclaimers from "../../components/disclaimers-sgi";
import CallToAction from "../../components/call-to-action-sgi";

// Static Data

import st_page from "../../data/page.json";
import st_property from "../../data/properties.json";
import st_documents from "../../data/documents.json";

const NewOfferPropertyHUD = () => {
  const { page_id } = useParams();

  const { hash } = useLocation();

  const offeringRef = useRef(null);
  const phaseRef = useRef(null);
  const groupRef = useRef(null);
  const leaderRef = useRef(null);
  const trackRef = useRef(null);
  const fundRef = useRef(null);

  const scrollToTargetAdjusted = (element) => {
    var headerOffset = 25;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const handleScrollPosition = (input_hash) => {
    switch (input_hash) {
      case "#offering":
        scrollToTargetAdjusted(offeringRef.current);
        break;
      case "#projects":
        scrollToTargetAdjusted(phaseRef.current);
        break;
      case "#alamo":
        scrollToTargetAdjusted(groupRef.current);
        break;
      case "#leadership_team":
        scrollToTargetAdjusted(leaderRef.current);
        break;
      case "#track_record":
        scrollToTargetAdjusted(trackRef.current);
        break;
      case "#fund_notes":
        scrollToTargetAdjusted(fundRef.current);
        break;

      default:
        return null;
    }
  };

  const [hello, setHello] = useState(true);

  useEffect(() => {
    setTimeout(handleScrollPosition(hash), 5000);
  }, [hello]);

  const user = useSelector((state) => state.user.data);
  const user_redux = useSelector((state) => state.user);
  const [listing, setListing] = useState();
  const [showInterest, setShowInterest] = useState(false);
  const [showWebinar, setShowWebinar] = useState(false);

  const [projectData, setProjectData] = useState({
    target_raise: 0,
    total_portfolio_value: 0,
    target_senior_loan: 0,
    target_pref_equity: 0,
    target_common_equity: 0,
    target_lp_equity: 0,
  });

  const getListingDetails = async () => {
    try {
      var result;
      // if (page_id) {
      //   result = await getListingByPageId(page_id);
      // } else {
      //   result = await getListingByPageId("default-id");
      // }

      result = await getListingByPageId("hud");
      setListing(result.data.response);

      // setListing(st_page);

      setHello(!hello);
    } catch (error) {
      console.log("🚀 ~ file: index.js:20 ~ getListingDetails ~ error", error);
    }
  };

  useEffect(() => {
    getListingDetails();
  }, [hash]);

  const handleInvest = () => {
    // window.open("/signup?type=invest&page_id=" + listing?.page_id, "_blank");
    // window.open(
    //   "https://alamoequity.invportal.com/app/offering-detail/6403af64f5b311001bfc5b4f",
    //   "_blank"
    // );
  };

  const init = {
    name: "",
    email: "",
    listing_id: "",
    offering_amount: "",
  };

  const onSubmit = async (values) => {
    try {
      await createInterest({ ...values, listing_id: listing?._id });

      toast.success(
        "We appreciate your interest in the project. We advise signing up for the webinar also."
      );
      setShowInterest(false);
      formik.resetForm();
    } catch (error) {
      responseToastMsg(error.data.error_code);
      console.log("🚀 ~ file: index.js ~ line 59 ~ handleSave ~ error", error);
    }
  };

  const formik = useFormik({
    initialValues: init,
    validationSchema: interestSchema,
    onSubmit,
  });

  const handleUpdateInput = (e, check_number = true) => {
    if (check_number) {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        formik.setFieldValue("offering_amount", e.target.value);
      }
    } else {
      formik.setFieldValue("offering_amount", e.target.value);
    }
  };

  const handleShowInterest = () => {
    // window.open("/signup?type=interest&page_id=" + listing?.page_id, "_blank");
    // window.open(
    //   "https://alamoequity.invportal.com/app/offering-detail/6403af64f5b311001bfc5b4f",
    //   "_blank"
    // );
    // setShowInterest(true);
    // console.log(user);
    // user &&
    //   formik.setValues({
    //     ...formik.values,
    //     name: user.name,
    //     email: user.email,
    //   });
  };

  return (
    <>
      <div className="property_wrapper_v2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="p_header">
                <div className="logo">
                  <a
                    href="http://www.sirerglobalinvestments.com"
                    target="_blank"
                  >
                    <img src={SGILogo} alt="" width={150} />
                  </a>
                </div>
                <div className="head_btn">
                  {/* <div className="text-header-title text-center">
                    <div className="row">
                      <div className="col">
                        <button
                          className="btn btn-light me-3 mb-2"
                          type="button"
                          onClick={handleShowInterest}
                        >
                          Express Interest
                        </button>
                        <button
                          className="btn btn-light me-3 mb-2"
                          type="button"
                          onClick={() => setShowWebinar(true)}
                        >
                          Webinars
                        </button>
                        <button
                          className="btn btn-primary mb-2 custom-invest-btn"
                          type="button"
                          onClick={handleInvest}
                        >
                          Invest Now
                        </button>
                      </div>
                    </div>
                  </div> */}
                  <Link to="/alamo" target="_blank">
                    <img src={Logo} alt="" width={150} />
                  </Link>
                </div>
              </div>
              <div className="head_btn_sm">
                {/* <div className="text-header-title text-center">
                  <div className="row">
                    <div className="col">
                      <button
                        className="btn btn-outline-primary me-3 mb-2"
                        type="button"
                        onClick={handleShowInterest}
                      >
                        Express Interest
                      </button>
                      <button
                        className="btn btn-outline-primary me-3 mb-2"
                        type="button"
                        onClick={() => setShowWebinar(true)}
                      >
                        Webinars
                      </button>
                      <button
                        className="btn btn-primary mb-2 ps-4 pe-4"
                        type="button"
                        onClick={handleInvest}
                      >
                        Invest Now
                      </button>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {/* <Fade bottom> */}
          <div className="row s_header align-items-center justify-content-center">
            <div className="col-12 h_header order-md-2 d-flex justify-content-between">
              <p className="h_text">TEXAS INVESTMENT OFFERING</p>
              <p>
                {/* {user_redux.login ? (
                  <a className="" href="/profile">
                    <span> Hello, {user.name}</span>
                  </a>
                ) : (
                  <a className="" href="/signin">
                    <span> Sign In</span>
                  </a>
                )} */}
              </p>
            </div>
            <div className="col-12 order-md-2">
              <div className="d-flex justify-content-between flex-wrap">
                <div className="flex-grow-1 text-start">
                  <h2>{listing?.title}</h2>
                </div>
                <div className="flex-grow-1 text-end">
                  {/* {!page_id && (
                    <>
                      <button
                        className="btn btn-outline-primary ps-4 pe-4 me-3"
                        type="button"
                        onClick={() => window.open("/sgi", "_blank")}
                      >
                        View Non-HUD Fund
                      </button>
                      <button
                        className="btn btn-outline-primary ps-4 pe-4"
                        type="button"
                        onClick={() =>
                          window.open(
                            "https://invest-india.alamoequity.com/",
                            "_blank"
                          )
                        }
                      >
                        View India Fund
                      </button>
                    </>
                  )} */}
                </div>
              </div>
            </div>
            {/* <div className="col-12 order-md-1 s_btn mb-2">
                <button
                  className="btn btn-outline-primary me-2 mb-2"
                  type="button"
                  onClick={handleShowInterest}
                >
                  Express Interest
                </button>
                <button
                  className="btn btn-outline-primary me-2 mb-2"
                  type="button"
                  onClick={() => setShowWebinar(true)}
                >
                  Register for Webinar
                </button>
                <button
                  className="btn btn-primary me-2 mb-2"
                  style={{ background: "#0d6efd !important" }}
                  type="button"
                  onClick={handleInvest}
                >
                  Invest Now
                </button>
              </div> */}
          </div>
          {/* </Fade> */}
          {/* <Fade bottom> */}
          <div className="row s_banner">
            <div className="col-12">
              {/* <ProjectImages projects={listing?.properties} /> */}
              <ProjectImagesList docsIds={listing?.listing_images} />
            </div>
          </div>
          {/* </Fade> */}
          {/* <div className="row s_banner">
            <div className="col-12">
              <div className="sbanner_inr">
                <img src={Property} alt="Propery"></img>
                <div className="cnt">
                  <h2>Secure Your Spot Today</h2>
                  <p>More Details Coming Soon.</p>
                </div>
              </div>
            </div>
          </div> */}
          {/* <Fade bottom cascade> */}
          <div className="row s_feature" id="fund-note-1">
            <div className="col-md-3">
              <div className="f_sec">
                <div className="icon">
                  <img src={Money} alt="Exit Icon" />
                </div>
                <div className="cont">
                  <h3>Total Project Cost</h3>
                  <h4>
                    {
                      numberFormatter(projectData?.total_portfolio_value).split(
                        "."
                      )[0]
                    }
                  </h4>
                  {/* <div className="fund-link">
                      <a href="#fund-notes" className="hyp-link">
                        Fund Note 2
                      </a>
                    </div> */}
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="f_sec">
                <div className="icon">
                  <img src={Money} alt="Exit Icon" />
                </div>
                <div className="cont">
                  <h3>
                    Total Loan
                    {/* <a href="#fund-notes" className="hyp-link">
                      <sup className="sup-cus">2</sup>
                    </a> */}
                  </h3>
                  <h4>
                    {
                      numberFormatter(projectData?.target_senior_loan).split(
                        "."
                      )[0]
                    }
                  </h4>
                  {/* <div className="fund-link">
                      <a href="#fund-notes" className="hyp-link">
                        Fund Note 2
                      </a>
                    </div> */}
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="f_sec">
                <div className="icon">
                  <img src={Target} alt="Exit Icon" />
                </div>
                <div className="cont">
                  <h3>
                    Total Equity
                    {/* <a href="#fund-notes" className="hyp-link">
                      <sup className="sup-cus">1</sup>
                    </a> */}
                  </h3>
                  <h4>
                    {
                      numberFormatter(projectData?.target_common_equity).split(
                        "."
                      )[0]
                    }
                  </h4>
                  {/* <div className="fund-link">
                      <a href="#fund-notes" className="hyp-link">
                        Fund Note 1
                      </a>
                    </div> */}
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="f_sec">
                <div className="icon">
                  <img src={Target} alt="Exit Icon" />
                </div>
                <div className="cont">
                  <h3>LP Equity</h3>
                  <h4>
                    {
                      numberFormatter(projectData?.target_lp_equity).split(
                        "."
                      )[0]
                    }
                  </h4>
                  {/* <div className="fund-link">
                      <a href="#fund-notes" className="hyp-link">
                        Fund Note 1
                      </a>
                    </div> */}
                </div>
              </div>
            </div>

            {/* <div className="col-md-4">
              <div className="f_sec">
                <div className="icon">
                  <img src={Loan} alt="Exit Icon" />
                </div>
                <div className="cont">
                  <h3>
                    Minimum Investment
                    <a href="#fund-notes" className="hyp-link">
                      <sup className="sup-cus">3</sup>
                    </a>
                  </h3>
                  <h4>
                    {currencyFormat(listing?.minimum_investment).split(".")[0]}
                    $100,000
                  </h4>

                  <div className="fund-link">
                      <a href="#fund-notes" className="hyp-link">
                        Fund Note 3
                      </a>
                    </div>
                </div>
              </div>
            </div> */}
          </div>
          {/* </Fade>
          <Fade bottom> */}
          <h4 className="mb-4 text-center" ref={phaseRef}>
            {/* Anticipated Phases of Capital Deployment{" "} */}
            Projected Capital Stack{" "}
          </h4>
          <div className="table table-responsive custom-border-primary mb-4">
            <ProjectsTable
              setProjectData={setProjectData}
              // projects={listing?.properties}
              projects={[
                "66aedb983c0ca3d6b5f6df01",
                "66aedba53c0ca3d6b5f6df02",
                "66aedbb73c0ca3d6b5f6df03",
                "66ae9c2bfd94b9d99e297c1e",
                "66aea1cefd94b9d99e297d1f",
              ]}
            />
          </div>
          {/* </Fade> */}
          {/* 
          <Fade bottom>
            <div className="phase">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div className="order-md-2 text-right">
                  Raise from January 2023 to June 2023
                </div>
                <div className="order-md-1">
                  <h4>Phase 1: $60 Million Equity</h4>
                </div>
              </div>

              <div className="table table-responsive">
                <ProjectsPhaseTable
                  projects={[
                    "638df2bde56a1b3c6896bc11",
                    "638fff9ed4392a25dfa378bb",
                  ]}
                />
              </div>
            </div>
          </Fade>

          <Fade bottom>
            <div className="phase">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div className="order-md-2 text-right">
                  Raise from July 2023 to December 2023
                </div>
                <div className="order-md-1">
                  <h4>Phase 2: $40 Million Equity</h4>
                </div>
              </div>
              <div className="table table-responsive">
                <ProjectsPhaseTable
                  projects={[
                    "639000aed4392a25dfa378d2",
                    "63900143d4392a25dfa378e9",
                  ]}
                />
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="phase">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div className="order-md-2 text-right">
                  Raise from January 2024 to June 2024
                </div>
                <div className="order-md-1">
                  <h4>Phase 3: $50 Million Equity</h4>
                </div>
              </div>

              <div className="table table-responsive">
                <ProjectsPhaseTable
                  projects={[
                    "638f9448dd0e1ed6c0c8737d",
                    "6390040fd4392a25dfa3791e",
                  ]}
                />
              </div>
            </div>
          </Fade> */}
          {/* 
          <div className="row s_more mb-4">
            <div className="col">
              <Fade bottom cascade>
                <p className="d-flex wrap-custom mb-0 justify-content-center">
                  <button
                    className="btn btn-primary me-2 mb-2 text-nowrap btn-primary-theme "
                    type="button"
                    onClick={handleShowInterest}
                  >
                    Express Interest
                  </button>
                  <button
                    className="btn btn-primary me-2 mb-2 text-nowrap btn-primary-theme "
                    type="button"
                    onClick={() => setShowWebinar(true)}
                  >
                    Register for Webinar
                  </button>
                  <button
                    className="btn btn-primary mb-2 text-nowrap btn-primary-theme "
                    type="button"
                    onClick={handleInvest}
                  >
                    Invest Now
                  </button>
                </p>
              </Fade>
            </div>
          </div> */}

          {/* <Fade bottom> */}
          {/* <div className="row s_header align-items-center justify-content-center mt-0">
            <div className="col-12 order-md-1 s_btn mb-2 text-center">
              <button
                className="btn btn-outline-primary me-2 mb-2"
                type="button"
                onClick={handleShowInterest}
              >
                Express Interest
              </button>
              <button
                className="btn btn-outline-primary me-2 mb-2"
                type="button"
                onClick={() => setShowWebinar(true)}
              >
                Webinars
              </button>
              <button
                className="btn btn-primary me-2 mb-2"
                style={{ background: "#0d6efd !important" }}
                type="button"
                onClick={handleInvest}
              >
                Invest Now
              </button>
            </div>
          </div> */}
          {/* </Fade> */}
          <div className="row s_more">
            {/* <Fade bottom> */}
            <div className="col-md-4 mb-md-0 mb-4  order-md-2">
              <div className="h_section">
                <div className="divide-section">
                  <h4>Offering Highlights</h4>
                  <hr />
                  <p>
                    Target Investor IRR: <b>{listing?.target_irr}</b>
                  </p>
                  <p>
                    Target Equity Multiple:{" "}
                    <b>{listing?.target_equity_multiple}</b>
                  </p>{" "}
                  <p>
                    Targeted Investment Period:{" "}
                    <b>{listing?.targeted_investment_period}</b>
                  </p>
                </div>
                <div className="d-none d-md-block">
                  <DeckDocs input={listing} />
                </div>
              </div>
            </div>
            {/* </Fade> */}

            <div className="col-md-8 order-md-1" id="offering">
              <div className="mr_over">
                {/* <Fade bottom> */}
                <h4 className="fw-bolder text-app-primary" ref={offeringRef}>
                  Offering Description
                </h4>
                {/* </Fade> */}
                <hr />
                {/* <Fade bottom cascade> */}
                <div>
                  <p className="text-justify offering-desc">
                    <b>Sirer Global Texas Investment Fund</b>, (the "Fund") will
                    be a <b>Special Purpose Investment Vehicle (SPIV)</b>{" "}
                    focused on funding limited partner investment capital for
                    single purpose entities that own or intends to purchase land
                    to develop and operate real estate projects in the United
                    States, primarily in the State of Texas. The SPV will be
                    formed through the efforts of <b>Mr. Ersin Sirer</b>,
                    Founder and Chairperson of Sirer Global Investments, and{" "}
                    <b>Mr. Logan Anjaneyulu</b>, (the "Founder"), Alamo
                    Multifamily GP, LLC, (the "General Partner"), Alamo Equity,
                    LLC (the "Investment Manager") and Alamo Multifamily Group,
                    LLC (the "Developer" or "AMG" and, collectively with Alamo
                    Equity and the General Partner, the "Alamo Group"). The
                    Sirer Investment Group (SIG), through this SPV as an
                    investment vehicle, intends to raise the{" "}
                    <b>
                      Limited Partner (LP) Equity of up to $125 Million (target)
                    </b>{" "}
                    in to develop approximately 1,100 Class A multifamily units;
                    1,218 Single Family Home Lots; and approximately 150,000 SFT
                    of Class A Retail developments spread across a portfolio of
                    five projects in the State of TX. The investment projects
                    currently being considered for this SPV portfolio are:
                  </p>
                  <p className="text-justify offering-desc mt-4 mb-4">
                    <ul>
                      <li>
                        <b>College Station Apartment Project</b> - 17.5 Acres
                        Land MF Zoned and Entitled Site; 336 Units; Class A;
                        3-Story Garden with surface parking; in College Station
                        / Bryan, TX close to TX A&M, large medical campus, and
                        hospital systems. This prime apartment site
                        pre-development, zoning, and entitlement work has been
                        under progress since January 2022 and is now fully
                        shovel ready with all engineering, architectural, and
                        permit work fully complete and the project is fully
                        permit ready to start construction work by as early as
                        October 1, 2024.
                      </li>
                      <li>
                        <b>Stone Oak Apartment Project</b> - 17.7 Acres Land MF
                        Zoned and Entitled Shovel Ready Site; 384 Units; Class
                        A+; 3-Story Garden with surface parking; in San Antonio,
                        TX on Stone Oak Parkway less than a mile from Hwy 281
                        and TPC Pkwy. This prime apartment site pre-development,
                        zoning, and entitlement work has been under progress
                        since summer of 2021 and is now fully shovel ready with
                        all engineering, architectural, and permit work fully
                        complete and the project is fully permit ready to start
                        construction work by as early as November 1, 2024.
                      </li>
                      <li>
                        <b>Afton Oak Apartment Project</b> - 15.7 Acres Land MF
                        Zoned and Entitled Shovel Ready Site; 360 Units; Class
                        A+; 4-Story Walkup with surface parking; in San Antonio,
                        TX south of 1604 and east of Stone Oak Parkway on 1604
                        by Little Woodrow's Stone Oak location on prime 1604
                        frontage site with proximity to San Antonio
                        International Airport and the world class JW Marriott
                        Resort and PGA Golf Course. This prime apartment site
                        pre-development, zoning, and entitlement work has been
                        under progress since August 2021 and is now fully shovel
                        ready with all engineering, architectural, and permit
                        work fully complete and the project is fully permit
                        ready to start construction work by as early as January
                        1, 2025.
                      </li>
                      <li>
                        <b>Georgetown Single Family Land Development Project</b>{" "}
                        - Rolling Meadows is the newest planned 228-acre
                        Single-Family Residence (SFR) Development project in the
                        Greater Austin, TX market in Georgetown/Jarrell area.
                        Extensive development work already completed in phase 1
                        including engineering, entitlements, EOPC's, utilities,
                        surveys, and site plans. Projected to develop +/- 1,218
                        Paper Lots ranging in size from 35', 40', and 45' home
                        sites. Rolling Meadows Subdivision is positioned within
                        both Georgetown and Jarrell School Districts.
                      </li>
                      <li>
                        <b>Afton Oaks Retail Project</b> - 13.04 Acres of Prime
                        1604 Frontage Retail Land Zoned and Entitled for prime
                        retail development to develop up to 200,000 SFT of Class
                        A retail family with mixed office, retail, and medical
                        office use with restaurants and high-end retail use.
                        This tract is well situated for the development of
                        commercial retail space and pad site(s) for restaurants
                        and general-purpose retail/services. The development of
                        Afton Retail Center will be accomplished by a new single
                        purpose entity formed to construct and operate the
                        project ("NewCo"). Based on its location and access we
                        believe this Tract can be developed as three (3)
                        Two-story multi-tenant buildings totaling approximately
                        200,000 square feet. The Afton Retail Center will
                        contain a mix of national, regional and local credit
                        tenants with the primary tenants being retail, mixed
                        office, restaurants, food and beverage joints. This
                        prime tract contains substantial 1604 highway frontage
                        and it lends itself to a linear project parallel to Loop
                        1604. The 3 main buildings will be separated by a
                        central plaza that provides a pedestrian link from Afton
                        Oaks North to Afton Oaks Apartment site and the rest of
                        the Afton Oaks Prime Development. The Property will be
                        developed using conservative assumptions in the same
                        manner as similar Alamo Development projects in San
                        Antonio. Using the land as collateral to obtain
                        construction financing, the buildings will be
                        constructed using good design fundamentals to provide
                        long term value for the Partnership.
                      </li>
                    </ul>
                  </p>

                  <p className="text-justify offering-desc">
                    Through this SPV, the <b>Sirer Investment Group (SIG)</b>{" "}
                    anticipates raising approximately{" "}
                    <u>
                      <b>$125 Million</b>
                    </u>{" "}
                    in Limited Partner Equity to deploy into these shovel ready
                    prime projects in the fast-growing <b>State of Texas</b> in
                    the United States of America. Four of these five projects
                    are fully zoned, entitled, permitted, and fully shovel ready
                    (College Station MF, Stone Oak MF, Afton Oak MF, and
                    Georgetown SFR), and the Afton Oaks Retail Center project is
                    under design and development phase with being shovel ready
                    by the end of the year 2024.
                  </p>

                  <p className="text-justify offering-desc">
                    The detailed project decks and project proformas can be
                    found in the data room upon signup.
                  </p>
                </div>
                {/* </Fade> */}
              </div>

              <div className="h_section d-md-none">
                {/* <Fade bottom cascade> */}
                <DeckDocs input={listing} />
                {/* </Fade> */}
              </div>
            </div>
          </div>

          {/* <Fade bottom>
            <div className="table table-responsive">
              <ProjectsTableOnlyName
                setProjectData={setProjectData}
                // projects={listing?.properties}
                projects={[
                  "638df2bde56a1b3c6896bc11",
                  "638fff9ed4392a25dfa378bb",
                  "639000aed4392a25dfa378d2",
                  "63900143d4392a25dfa378e9",
                  "638f9448dd0e1ed6c0c8737d",
                  "6390040fd4392a25dfa3791e",
                ]}
              />
            </div>
          </Fade> */}
          <div className="row s_more">
            <div className="col">
              <People
                leaderRef={leaderRef}
                groupRef={groupRef}
                trackRef={trackRef}
              />
              <div className="notes-addon" id="fund-notes" ref={fundRef}>
                {/* <Fade bottom> */}
                <h4 className="fw-bolder">SPV Notes</h4>
                {/* </Fade> */}
                <hr />
                {/* <Fade bottom> */}
                <p className="text-justify offering-desc mb-3">
                  <ul>
                    <li>
                      <b>
                        <a href="#fund-note-1">SPV Note 1</a>: Target Equity
                        Raise
                        {/* <sup className="sup-cus">1</sup> */}
                      </b>{" "}
                      - Target SPV LP equity raise is $125 million, an estimated
                      40% equity based on securing an estimated 60% Loan-to-cost
                      via senior loans and bank debts.
                    </li>

                    <li>
                      <b>
                        <a href="#fund-note-1">SPV Note 2</a>: Total Project
                        Cost
                        {/* <sup className="sup-cus">2</sup>{" "} */}
                      </b>{" "}
                      - The Total Project Cost (TPC) of $340 million is based on
                      an anticipated and best possible estimated value on a high
                      level scope of number of units, average unit sizes, and
                      current estimated and anticipated construction costs at
                      the time of modelling for each of these projects. These
                      numbers could vary depending on more definite cost
                      estimates once we have firm GMP prices post completion of
                      construction drawings for each of these projects. The
                      Portfolio indicates a Projected Portfolio as anticipated
                      by the Sponsor. Projects within the Projected Portfolio
                      may be replaced or removed at any time prior to actual
                      investment by the Fund in the sole discretion of the
                      Sponsor and the Total Portfolio Value may be adjusted
                      accordingly. Prospective Investors should not rely on this
                      information as a basis for an investment in the SPV. The
                      information presented here is based on assumptions that
                      may not be achieved.
                    </li>

                    <li>
                      <b>
                        <a href="#fund-note-1">SPV Note 3</a>: Minimum
                        Investment
                        {/* <sup className="sup-cus">3</sup>{" "} */}
                      </b>{" "}
                      - The minimum investment amount into the SPV to be
                      determined by Sirer Investment Group (SIG).
                    </li>

                    <li>
                      <b>
                        <a href="#fund-note-1">SPV Note 4</a>: Phases and
                        Projects
                        {/* <sup className="sup-cus">4</sup>{" "} */}
                      </b>{" "}
                      - The deployment phases are identified arbitrarily and the
                      projects identified under each are also arbitrary at this
                      time. This is the anticipated funds deployment timeline
                      and is subject to change at any time for any reason as the
                      fundraise and project finalization progresses. Funds may
                      be used by the Company immediately upon acceptance of any
                      such funds by the General Partner, the phase-based outline
                      set forth above is a general deployment plan only. All
                      information provided, including, but not limited to, the
                      Project, Units, Project Cost, Project Debt, Project
                      Equity, and $/Unit are all projected values based on an
                      anticipated and best possible estimated value on a
                      high-level scope. The Portfolio indicates a Projected
                      Portfolio as anticipated by the Sponsor. Projects within
                      the Projected Portfolio may be replaced or removed at any
                      time prior to actual investment by the Fund in the sole
                      discretion of the Sponsor. Prospective Investors should
                      not rely on this information as a basis for an investment
                      in the Fund. The information presented here is based on
                      assumptions that may not be achieved.
                    </li>

                    <li>
                      <b>
                        <a href="#offering">SPV Note 5</a>: Offering Description
                        {/* <sup className="sup-cus">5</sup>{" "} */}
                      </b>{" "}
                      - The above is a Projected Portfolio as anticipated by The
                      Sirer Group and The Alamo Group. Projects within the
                      Portfolio may be replaced or removed from the Portfolio at
                      any time prior to actual investment by the SPV in the sole
                      discretion of the Sponsor Group. The above projects have
                      real estate under contract or currently being negotiated
                      by special purpose entities in which the SPV is
                      considering investment, and for which due diligence is
                      currently ongoing. Prospective Investors should not rely
                      on this information as a basis for an investment into the
                      SPV. The information presented here is based on
                      assumptions that may not be achieved.
                    </li>
                  </ul>
                </p>
                {/* </Fade> */}
                {/* <p className="text-justify offering-desc mb-3"></p>
            <p className="text-justify offering-desc mb-0"></p> */}
              </div>
              {/* <CallToAction /> */}
              <Disclaimers />
              {/* <CallToAction /> */}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showInterest}
        onHide={() => setShowInterest(false)}
        size="lg"
      >
        <ModalHeader closeButton>
          <h5>Express Interest</h5>
        </ModalHeader>
        <ModalBody>
          <h6 className="mb-0">{listing?.title}</h6>
          <small className="text-secondary">
            Target IRR: {listing?.target_irr}% | Target Equity Multiple:{" "}
            {listing?.target_equity_multiple}
          </small>
          <p className="mt-3">
            Thank you for expressing your interest in this offering. Please
            provide the following details to keep you posted.
          </p>
          <form onSubmit={formik.handleSubmit}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label>Full Name</label>
                    <input
                      type="text"
                      placeholder="Name"
                      value={formik.values.name}
                      name="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`form-control ${
                        formik.touched.name &&
                        (formik.errors.name
                          ? "is-invalid"
                          : !formik.errors.name && "is-valid")
                      }`}
                    />
                    {formik.errors.name && formik.touched.name && (
                      <p className="text-danger">{formik.errors.name}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="Email"
                      value={formik.values.email}
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`form-control ${
                        formik.touched.email &&
                        (formik.errors.email
                          ? "is-invalid"
                          : !formik.errors.email && "is-valid")
                      }`}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <p className="text-danger">{formik.errors.email}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label>
                      How much would you like to invest in this project?
                    </label>
                    <input
                      type="text"
                      placeholder="Offering Amount($)"
                      value={formik.values.offering_amount}
                      name="offering_amount"
                      onChange={handleUpdateInput}
                      onBlur={formik.handleBlur}
                      className={`form-control ${
                        formik.touched.offering_amount &&
                        (formik.errors.offering_amount
                          ? "is-invalid"
                          : !formik.errors.offering_amount && "is-valid")
                      }`}
                    />
                    {formik.errors.offering_amount &&
                      formik.touched.offering_amount && (
                        <p className="text-danger">
                          {formik.errors.offering_amount}
                        </p>
                      )}
                  </div>
                </div>

                <div className="col-md-12">
                  <button type="submit" className="btn btn-primary mb-4">
                    Sumbit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

      <Modal show={showWebinar} onHide={() => setShowWebinar(false)} size="md">
        <ModalHeader closeButton>
          <h5>Webinars</h5>
        </ModalHeader>
        <ModalBody>
          {/* <h5 className="border-bottom">{listing?.title}</h5> */}

          <h6>Upcoming Webinars</h6>
          <div className="row">
            {listing?.webinars?.length > 0 ? (
              listing?.webinars?.map((o, i) => (
                <div className="col-md-12">
                  <div className="mb-3 border p-3 rounded">
                    <h6>{o.title}</h6>
                    <div>
                      <small className="text-secondary">{o.time}</small>
                    </div>
                    <div className="mt-2">
                      <a
                        href={o.link}
                        target="_blank"
                        className="btn btn-primary btn-sm"
                      >
                        Register Now
                      </a>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>There are no upcoming webinars.</div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const DocumentList = ({
  docIds = [],
  login = false,
  handleDownload = () => {},
}) => {
  const [list, setList] = useState([]);

  const getListDoc = async () => {
    try {
      const result = await getMultiDocument(docIds);
      setList(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: index.js:159 ~ getListDoc ~ error", error);
    }
  };

  useEffect(() => {
    docIds.length > 0 && getListDoc();
  }, [docIds.length]);

  return (
    <>
      {list?.map((o) => (
        <a
          href={o.document.url}
          download
          target="_blank"
          onClick={() => handleDownload(o)}
        >
          <FaRegFilePdf size={30} color={"#B30B00"} className="me-2" />
          {o.document.name.replace(".pdf", "")}
        </a>
      ))}
    </>
  );
};

const DeckDocs = ({ input }) => {
  const user = useSelector((state) => state.user.data);

  const [listing, setListing] = useState();
  useEffect(() => {
    setListing(input);
  }, [input]);

  const handleDownload = async (input) => {
    try {
      await logDownloads(input._id, user.user_id);
    } catch (error) {
      console.log("🚀 ~ file: index.js:906 ~ handleDownload ~ error", error);
    }
  };

  return (
    <>
      <div className="divide-section">
        <div className="docs">
          <h4>SPV Documents</h4>
          <hr />
          {/* <DocumentList
            docIds={listing?.listing_documents}
            login={user.token ? true : false}
            handleDownload={handleDownload}
          /> */}

          <ProjectsTableOnlyName
            append="SPV"
            onlyName
            projects={[
              "66aeaa3a3c0ca3d6b5f6defe",
              "66aeaa5c3c0ca3d6b5f6deff",
              "66aeaa673c0ca3d6b5f6df00",
              "66ae9c2bfd94b9d99e297c1e",
              "66aea1cefd94b9d99e297d1f",
            ]}
          />
        </div>
      </div>
      <div className="divide-section">
        <div className="docs">
          <h4>Developer Info</h4>
          <hr />
          <DocumentList
            docIds={listing?.company_deck_documents}
            //docIds={["64c8bbd8edcd9b3b979b9df8"]}// old - 64541a87d0e80312e0c2041b
            login={user.token ? true : false}
            handleDownload={handleDownload}
          />
        </div>
      </div>
      <div className="divide-section">
        <div className="docs">
          <h4>Why Texas?</h4>
          <hr />
          <a
            href={
              "https://ik.imagekit.io/alamogroup/dev/Will%20US%20companies%20keep%20faith%20in%20the%20_Texas%20miracle__.pdf?updatedAt=1722791055130"
            }
            target="_blank"
          >
            <FaRegFilePdf size={30} color={"#B30B00"} className="me-2" />
            Why Texas? - FT Article July 25, 2024
          </a>
          <a
            href={
              "https://ik.imagekit.io/alamogroup/dev/Texas%20Map.pdf?updatedAt=1722791053532"
            }
            target="_blank"
          >
            <FaRegFilePdf size={30} color={"#B30B00"} className="me-2" />
            State of Texas Golden Triangle
          </a>
          <a
            href={
              "https://ik.imagekit.io/alamogroup/dev/Why%20Texas%20Slide.pdf?updatedAt=1722791053192"
            }
            target="_blank"
          >
            <FaRegFilePdf size={30} color={"#B30B00"} className="me-2" />
            State of Texas - Economic Facts & Figures
          </a>
        </div>
      </div>
      <div className="divide-section">
        <div className="docs">
          <h4>Why San Antonio?</h4>
          <hr />

          <a
            href={
              "https://ik.imagekit.io/alamogroup/dev/Why%20San%20Antonio.pdf?updatedAt=1722791433775"
            }
            target="_blank"
          >
            <FaRegFilePdf size={30} color={"#B30B00"} className="me-2" />
            San Antonio MSA
          </a>

          <a
            href={
              "https://ik.imagekit.io/alamogroup/dev/San%20Antonio%20Submarket%20Overview.pdf?updatedAt=1722791693467"
            }
            target="_blank"
          >
            <FaRegFilePdf size={30} color={"#B30B00"} className="me-2" />
            San Antonio Submarket Overview
          </a>
        </div>
      </div>
      <div className="divide-section">
        <div className="docs">
          <h4>Why College Station?</h4>
          <hr />
          <a
            href={
              "https://ik.imagekit.io/alamogroup/dev/College%20Station%20Submarket%20Overview.pdf?updatedAt=1722791752119"
            }
            target="_blank"
          >
            <FaRegFilePdf size={30} color={"#B30B00"} className="me-2" />
            College Station Submarket Overview
          </a>
        </div>
      </div>
      <div className="divide-section">
        <div className="docs">
          <h4>Why Austin/Georgetown?</h4>
          <hr />

          <a
            href={
              "https://ik.imagekit.io/alamogroup/dev/Why%20Austin%20MSA.pdf?updatedAt=1722791888578"
            }
            target="_blank"
          >
            <FaRegFilePdf size={30} color={"#B30B00"} className="me-2" />
            Austin/Georgetown Market Overview
          </a>
        </div>
      </div>
      {/* <div className="divide-section">
        <div className="docs">
          <h4>Project Decks</h4>
          <hr />
          <DocumentList
            docIds={listing?.portfolio_deck_documents}
            login={user.token ? true : true}
            handleDownload={handleDownload}
          />
        </div>
      </div> */}
      <div className="divide-section mb-0">
        <div className="docs">
          <h4>Project Info</h4>
          <hr />
          <ProjectsTableOnlyName
            onlyName
            projects={[
              "66aedb983c0ca3d6b5f6df01",
              "66aedba53c0ca3d6b5f6df02",
              "66aedbb73c0ca3d6b5f6df03",
              "66ae9c2bfd94b9d99e297c1e",
              "66aea1cefd94b9d99e297d1f",
            ]}
          />
        </div>
      </div>
    </>
  );
};

const SingleImage = ({ docIds = [] }) => {
  const [list, setList] = useState([]);

  const getListDoc = async () => {
    try {
      const result = await getMultiDocument(docIds);
      setList(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: index.js:159 ~ getListDoc ~ error", error);
    }
  };

  useEffect(() => {
    getListDoc();
  }, []);

  return (
    <>
      <img
        style={{
          objectFit: "cover",
          // objectPosition: "top",
        }}
        className="d-block w-100 cover-banner"
        src={list[0]?.document?.url}
        alt={list[0]?.document.name}
      />
    </>
  );
};

const ProjectImages = ({ projects = [] }) => {
  const [list, setList] = useState([]);

  const getListDoc = async () => {
    try {
      const result = await getMultiProperty(projects);
      setList(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: index.js:159 ~ getListDoc ~ error", error);
    }
  };

  useEffect(() => {
    projects.length > 0 && getListDoc();
  }, [projects]);

  return (
    <Carousel>
      {list.map((obj) => (
        <Carousel.Item>
          <SingleImage docIds={[obj?.property_image[0]]} />
          <Carousel.Caption>
            <h3 className="txt-shadow">{obj.name}</h3>
            <p className="txt-shadow">{obj.address}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

const ProjectsTableOnlyName = ({
  append = "",
  setProjectData = () => {},
  onlyName = false,
  projects = [],
}) => {
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [prop, setProp] = useState();

  const [totals, setTotals] = useState({
    units: 0,
    project_cost: 0,
    project_debt: 0,
    project_equity: 0,
    dollar_per_unit: 0,
    buildings: 0,
  });

  const getProjectList = async () => {
    try {
      const result = await getMultiProperty(projects);

      var _list = [];

      for (var xx = 0; xx < projects.length; xx++) {
        _list.push(result.data.response.find((z) => z._id === projects[xx]));
      }

      setList(_list);

      getCalc(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: index.js:159 ~ getProjectList ~ error", error);
    }
  };

  const getCalc = (res_list) => {
    var _target_equity = 0,
      _target_portfolio = 0,
      _units = 0,
      _project_cost = 0,
      _project_debt = 0,
      _project_equity = 0,
      _dollar_per_unit = 0,
      _buildings = 0;
    for (var x = 0; x < res_list.length; x++) {
      _target_portfolio += res_list[x].project_cost;
      _target_equity += res_list[x].project_equity;

      _units += res_list[x].units;
      _project_cost += res_list[x].project_cost;
      _project_debt += res_list[x].project_debt;
      _project_equity += res_list[x].project_equity;
      _dollar_per_unit += res_list[x].dollar_per_unit;
      _buildings += res_list[x].no_of_buildings;
    }

    setProjectData({
      target_raise: _target_equity,
      total_portfolio_value: _target_portfolio,
    });

    setTotals({
      units: _units,
      project_cost: _project_cost,
      project_debt: _project_debt,
      project_equity: _project_equity,
      dollar_per_unit: _dollar_per_unit / res_list.length,
      buildings: _buildings,
    });
  };

  useEffect(() => {
    projects.length > 0 && getProjectList();
  }, []);

  return (
    <>
      {onlyName ? (
        <table className="">
          <tbody>
            {list.map((o, i) => (
              <tr
                key={`row_proj_${i}`}
                role={"button"}
                onClick={() => {
                  setShow(true);
                  setProp(o);
                }}
              >
                <td className="pb-1">
                  <a href="javascript:;" className="svg-icon-height">
                    <BiBuildingHouse color={"#0c3c60"} className="me-2" />{" "}
                    {append ? o.name.replace("Project", "SPV") : o.name}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <table className="table table-hover mb-0">
          <thead>
            <tr className="theme-bg-table">
              <th className="text-center">S.No.</th>
              <th>Project Name</th>
              <th className="text-center">Units</th>
              <th className="text-center">Project Cost</th>
              <th className="text-center">Project Debt</th>
              <th className="text-center">Project Equity</th>
              <th className="text-center">$/Unit</th>
              <th className="text-center">Project Type</th>
              <th className="text-center">Buildings</th>
              <th className="text-start">Location</th>
              {/* <th className="text-start">Project Info</th> */}
            </tr>
          </thead>
          <tbody>
            {list.map((o, i) => (
              <tr
                key={`row_proj_${i}`}
                role={"button"}
                onClick={() => {
                  setShow(true);
                  setProp(o);
                }}
              >
                <td className="text-center">{i + 1}.</td>
                <td>
                  {o.is_deck_active === true && o.deck_link ? (
                    <a
                      href={o.deck_link}
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {o.name}
                    </a>
                  ) : (
                    <>{o.name}</>
                  )}
                </td>
                <td className="text-center">{o.units}</td>
                <td className="text-center">
                  {numberFormatter(o.project_cost)}
                </td>
                <td className="text-center">
                  {numberFormatter(o.project_debt)}
                </td>
                <td className="text-center">
                  {numberFormatter(o.project_equity)}
                </td>
                <td className="text-center">
                  {numberFormatter(o.dollar_per_unit, 0)}
                </td>
                <td className="text-center">{o.project_type}</td>
                <td className="text-center">{o.no_of_buildings}</td>
                {/* <td className="text-start">{`${o.city},${o.state}`}</td> */}
                <td className="text-start">
                  {o.google_pin_link ? (
                    <a
                      href={o.google_pin_link}
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {`${o.city},${o.state}`}
                    </a>
                  ) : (
                    <>{`${o.city},${o.state}`}</>
                  )}
                </td>
              </tr>
            ))}
            <tr className="custom-bg-footer">
              <th></th>
              <th>
                <>Portfolio Totals</>
              </th>
              <th>{numberFormatterOnly(totals.units)}</th>
              <th className="text-center">
                {numberFormatter(totals.project_cost)}
              </th>
              <th className="text-center">
                {numberFormatter(totals.project_debt)}
              </th>
              <th className="text-center">
                {numberFormatter(totals.project_equity)}
              </th>
              <th className="text-center">
                {numberFormatter(totals.dollar_per_unit, 0)}
              </th>
              <th className="text-center">Mixed Variety</th>
              <th className="text-center">{totals.buildings}</th>
              <th>Multiple TX</th>
              {/* <th>
                  {deck_link ? (
                    <a href={deck_link} target="_blank">
                      {deck_name}
                    </a>
                  ) : (
                    <>{deck_name}</>
                  )}
                </th> */}
            </tr>
          </tbody>
        </table>
      )}

      <Modal show={show} onHide={() => setShow(false)} size={"lg"}>
        <ModalHeader closeButton>
          <div className="container-fluid">
            <h5 className="mb-0 w-100">{prop?.name}</h5>
            <small className="">{prop?.address}</small>
          </div>
        </ModalHeader>
        <ModalBody>
          <ProjectImagesList docsIds={prop?.property_image} />

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div className="card-like">
                  <label>Project Cost</label>
                  <div className="value">
                    {numberFormatter(prop?.project_cost)}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Project Equity</label>
                  <div className="value">
                    {numberFormatter(prop?.project_equity)}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Project Debt</label>
                  <div className="value">
                    {numberFormatter(prop?.project_debt)}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Project Type</label>
                  <div className="value">{prop?.project_type}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Units</label>
                  <div className="value">{prop?.units}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Acreage</label>
                  <div className="value">
                    {prop?.acreage ? prop?.acreage : "TBA"}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Target IRR</label>
                  <div className="value">
                    {prop?.targeted_investor_irr
                      ? prop?.targeted_investor_irr
                      : "TBA"}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Target Equity</label>
                  <div className="value">
                    {prop?.targeted_equity_multiple
                      ? prop?.targeted_equity_multiple
                      : "TBA"}
                  </div>
                </div>
              </div>
              <div className="col-12 text-justify">
                <p className="help-text-modal ">
                  * All information contained herein are projected values based
                  on an anticipated and best possible estimated value on a
                  high-level scope. Prospective Investors should not rely on
                  this information as a basis for an investment in the Fund. The
                  information presented here is based on assumptions that may
                  not be achieved.
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const ProjectsTable = ({
  setProjectData = () => {},
  onlyName = false,
  projects = [],
}) => {
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [prop, setProp] = useState();

  const { page_id } = useParams();

  const [totals, setTotals] = useState({
    units: 0,
    project_cost: 0,
    project_debt: 0,
    project_equity: 0,
    lp_equity: 0,
    gp_equity: 0,
    exit_price: 0,
    buildings: 0,
  });

  const getProjectList = async () => {
    try {
      const result = await getMultiProperty(projects);

      var _list = [];

      for (var xx = 0; xx < projects.length; xx++) {
        _list.push(result.data.response.find((z) => z._id === projects[xx]));
      }

      setList(_list);
      getCalc(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: index.js:159 ~ getProjectList ~ error", error);
    }
  };

  const getCalc = (res_list) => {
    var _target_equity = 0,
      _target_portfolio = 0,
      _units = 0,
      _project_cost = 0,
      _project_debt = 0,
      _project_equity = 0,
      _lp_equity = 0,
      _gp_equity = 0,
      _exit_price = 0,
      _buildings = 0;
    for (var x = 0; x < res_list.length; x++) {
      _target_portfolio += res_list[x].project_cost;
      _target_equity += res_list[x].project_equity;

      _lp_equity += res_list[x].lp_equity;
      _gp_equity += res_list[x].gp_equity;

      _units += res_list[x].units;
      _project_cost += res_list[x].project_cost;
      _project_debt += res_list[x].project_debt;
      _project_equity += res_list[x].project_equity;
      _exit_price += res_list[x].exit_price ? res_list[x].exit_price : 0;
      _buildings += res_list[x].no_of_buildings;
    }

    setProjectData({
      target_raise: _lp_equity + _gp_equity,
      total_portfolio_value: _target_portfolio,
      target_senior_loan: _project_debt,
      target_lp_equity: _lp_equity,
      target_common_equity: _lp_equity + _gp_equity,
    });

    setTotals({
      units: _units,
      project_cost: _project_cost,
      project_debt: _project_debt,
      project_equity: _project_equity,

      lp_equity: _lp_equity,
      gp_equity: _gp_equity,

      exit_price: _exit_price,
      buildings: _buildings,
    });
  };

  useEffect(() => {
    projects.length > 0 && getProjectList();
  }, []);

  return (
    <>
      <table className="table table-hover mb-0">
        <thead>
          <tr className="theme-bg-table">
            <th>
              Project Name{" "}
              {/* <a href="#fund-notes" className="hyp-link">
                <sup className="sup-cus-low" style={{ color: "yellow" }}>
                  Fund Note 4
                </sup>
              </a> */}
            </th>
            <th className="text-center">Units</th>
            <th className="text-center">Total Cost</th>
            <th className="text-center">Total Loan</th>
            <th className="text-center">Total Equity</th>
            <th className="text-center">LP Equity</th>
            <th className="text-center">GP Equity</th>
            {/* <th className="text-center">$/Unit</th> */}
            <th className="text-center">Exit Price</th>
            {/* <th className="" style={{ paddingLeft: "2rem", width: "1rem" }}>
              Project Type
            </th> */}
            {/* <th className="text-center">Buildings</th> */}
            <th className="text-start">Location</th>
            {/* <th className="text-start">Project Info</th> */}
          </tr>
        </thead>
        <tbody>
          {list.map((o, i) => {
            const Actual = () => {
              return (
                <tr
                  key={`row_proj_${i}`}
                  role={"button"}
                  onClick={() => {
                    setShow(true);
                    setProp(o);
                  }}
                >
                  {/* <td className="text-center">{i + 1}.</td> */}
                  <td>
                    {o.is_deck_active === true && o.deck_link ? (
                      <a
                        href={o.deck_link}
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {o.name}
                      </a>
                    ) : (
                      <>{o.name}</>
                    )}
                  </td>
                  <td className="text-center">
                    {numberFormatterOnly(o.units)}
                  </td>
                  <td className="text-center">
                    {numberFormatter(o.project_cost)}
                  </td>
                  <td className="text-center">
                    {numberFormatter(o.project_debt)}
                  </td>
                  <td className="text-center">
                    {numberFormatter(o.lp_equity + o.gp_equity)}
                  </td>
                  <td className="text-center">
                    {numberFormatter(o.lp_equity)}
                  </td>
                  <td className="text-center">
                    {numberFormatter(o.gp_equity)}
                  </td>
                  <td className="text-center">
                    {numberFormatter(o.exit_price)}
                  </td>
                  {/* <td className="" style={{ paddingLeft: "2rem" }}>
                    {o.project_type}
                  </td>
                  <td className="text-center">{o.no_of_buildings}</td> */}
                  {/* <td className="text-start">{`${o.city},${o.state}`}</td> */}
                  <td className="text-start">
                    {o.google_pin_link ? (
                      <a
                        href={o.google_pin_link}
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {`${o.city},${o.state}`}
                      </a>
                    ) : (
                      <>{`${o.city},${o.state}`}</>
                    )}
                  </td>
                </tr>
              );
            };

            switch (i) {
              case 0:
                return (
                  <>
                    <tr className="phase-row">
                      <td colSpan={9}>
                        <div className="row align-items-center">
                          <div className="col col-md-auto">
                            <h6>
                              Multifamily Projects
                              {/* Deployment Phase 1: $125 Million Equity ($50
                              Million {!page_id&& 'Pref'} Equity and $75 Million Common Equity)
                              <a href="#fund-notes" className="hyp-link">
                                <sup className="sup-cus-low">4</sup>
                              </a> */}
                            </h6>
                          </div>
                          {/* <div className="col-12 col-md-auto p-0">
                            <small className="ms-3 ms-md-0">
                              - Anticipated Deployment from July 2023 to March
                              2024
                            </small>
                          </div> */}
                        </div>
                      </td>
                    </tr>
                    <Actual />
                  </>
                );

              case 3:
                return (
                  <>
                    <tr className="phase-row">
                      <td colSpan={9}>
                        <div className="row align-items-center">
                          <div className="col col-md-auto">
                            <h6>Single Family Land Project</h6>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <Actual />
                  </>
                );

              case 4:
                return (
                  <>
                    <tr className="phase-row">
                      <td colSpan={9}>
                        <div className="row align-items-center">
                          <div className="col col-md-auto">
                            <h6>Retail Project</h6>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <Actual />
                  </>
                );

              default:
                return (
                  <>
                    <Actual />
                  </>
                );
            }
          })}
        </tbody>
        <tfoot>
          <tr
            className="theme-bg-table"
            style={{ borderTop: "20px solid #eee" }}
          >
            <th>
              <>
                Portfolio Totals{" "}
                {/* <a href="#fund-notes" className="hyp-link">
                  <sup className="sup-cus-low" style={{ color: "yellow" }}>
                    Fund Note 4
                  </sup>
                </a> */}
              </>
            </th>
            <th>{numberFormatterOnly(totals.units)}</th>
            <th className="text-center">
              {numberFormatter(totals.project_cost)}
            </th>
            <th className="text-center">
              {numberFormatter(totals.project_debt)}
            </th>
            {/* <th className="text-center">
              {numberFormatter(totals.project_equity)}
            </th> */}
            <th className="text-center">
              {numberFormatter(totals.lp_equity + totals.gp_equity)}
            </th>
            <th className="text-center">{numberFormatter(totals.lp_equity)}</th>{" "}
            <th className="text-center">{numberFormatter(totals.gp_equity)}</th>
            <th className="text-center">
              {numberFormatter(totals.exit_price, 0)}
            </th>
            {/* <th className="text-center">Mixed Variety</th>
            <th className="text-center">{totals.buildings}</th> */}
            <th>Multiple TX</th>
            {/* <th>
                  {deck_link ? (
                    <a href={deck_link} target="_blank">
                      {deck_name}
                    </a>
                  ) : (
                    <>{deck_name}</>
                  )}
                </th> */}
          </tr>
        </tfoot>
      </table>

      <Modal show={show} onHide={() => setShow(false)} size={"lg"}>
        <ModalHeader closeButton>
          <div className="container-fluid">
            <h5 className="mb-0 w-100">{prop?.name}</h5>
            <small className="">{prop?.address}</small>
          </div>
        </ModalHeader>
        <ModalBody>
          <ProjectImagesList docsIds={prop?.property_image} />

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div className="card-like">
                  <label>Total Cost</label>
                  <div className="value">
                    {numberFormatter(prop?.project_cost)}
                  </div>
                </div>
              </div>
              {/* <div className="col-md-3">
                <div className="card-like">
                  <label>Project Equity</label>
                  <div className="value">
                    {numberFormatter(prop?.project_equity)}
                  </div>
                </div>
              </div> */}

              <div className="col-md-3">
                <div className="card-like">
                  <label>Total Equity</label>
                  <div className="value">
                    {numberFormatter(prop?.lp_equity + prop?.gp_equity)}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="card-like">
                  <label>LP Equity</label>
                  <div className="value">
                    {numberFormatter(prop?.lp_equity)}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="card-like">
                  <label>GP Equity</label>
                  <div className="value">
                    {numberFormatter(prop?.gp_equity)}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="card-like">
                  <label>Total Loan</label>
                  <div className="value">
                    {numberFormatter(prop?.project_debt)}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Project Type</label>
                  <div className="value">{prop?.project_type}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Units</label>
                  <div className="value">
                    {numberFormatterOnly(prop?.units)}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Acreage</label>
                  <div className="value">
                    {prop?.acreage ? prop?.acreage : "TBA"}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Target IRR</label>
                  <div className="value">
                    {prop?.targeted_investor_irr
                      ? prop?.targeted_investor_irr
                      : "TBA"}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="card-like">
                  <label>Target Equity</label>
                  <div className="value">
                    {prop?.targeted_equity_multiple
                      ? prop?.targeted_equity_multiple
                      : "TBA"}
                  </div>
                </div>
              </div>

              <div className="col-12 text-justify">
                <p className="help-text-modal ">
                  * All information contained herein are projected values based
                  on an anticipated and best possible estimated value on a
                  high-level scope. Prospective Investors should not rely on
                  this information as a basis for an investment in the Fund. The
                  information presented here is based on assumptions that may
                  not be achieved.
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const ProjectsPhaseTable = ({ onlyName = false, projects = [] }) => {
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [prop, setProp] = useState();

  const [totals, setTotals] = useState({
    units: 0,
    project_cost: 0,
    project_debt: 0,
    project_equity: 0,
    dollar_per_unit: 0,
    buildings: 0,
  });

  const getProjectList = async () => {
    try {
      const result = await getMultiProperty(projects);
      setList(result.data.response);
      getCalc(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: index.js:159 ~ getProjectList ~ error", error);
    }
  };

  const getCalc = (res_list) => {
    var _target_equity = 0,
      _target_portfolio = 0,
      _units = 0,
      _project_cost = 0,
      _project_debt = 0,
      _project_equity = 0,
      _dollar_per_unit = 0,
      _buildings = 0;
    for (var x = 0; x < res_list.length; x++) {
      _target_portfolio += res_list[x].project_cost;
      _target_equity += res_list[x].project_equity;

      _units += res_list[x].units;
      _project_cost += res_list[x].project_cost;
      _project_debt += res_list[x].project_debt;
      _project_equity += res_list[x].project_equity;
      _dollar_per_unit += res_list[x].dollar_per_unit;
      _buildings += res_list[x].no_of_buildings;
    }

    setTotals({
      units: _units,
      project_cost: _project_cost,
      project_debt: _project_debt,
      project_equity: _project_equity,
      dollar_per_unit: _dollar_per_unit / res_list.length,
      buildings: _buildings,
    });
  };

  useEffect(() => {
    projects.length > 0 && getProjectList();
  }, [projects]);

  return (
    <>
      {onlyName ? (
        <table className="mb-4">
          <tbody>
            {list.map((o, i) => (
              <tr
                key={`row_proj_${i}`}
                role={"button"}
                onClick={() => {
                  setShow(true);
                  setProp(o);
                }}
              >
                <td className="pb-1">
                  <a href="javascript:;" className="svg-icon-height">
                    <BiBuildingHouse color={"#0c3c60"} className="me-2" />{" "}
                    {o.name}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <table className="table table-hover mb-4">
          <thead>
            <tr className="theme-bg-table">
              <th className="text-center">S.No.</th>
              <th>Project Name</th>
              <th className="text-center">Units</th>
              <th className="text-center">Project Cost</th>
              <th className="text-center">Project Debt</th>
              <th className="text-center">Project Equity</th>
              <th className="text-center">$/Unit</th>
              <th className="text-center">Project Type</th>
              <th className="text-center">Buildings</th>
              <th className="text-start">Location</th>
              {/* <th className="text-start">Project Info</th> */}
            </tr>
          </thead>
          <tbody>
            {list.map((o, i) => (
              <tr
                key={`row_proj_${i}`}
                role={"button"}
                onClick={() => {
                  setShow(true);
                  setProp(o);
                }}
              >
                <td className="text-center">{i + 1}.</td>
                <td>
                  {o.is_deck_active === true && o.deck_link ? (
                    <a
                      href={o.deck_link}
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {o.name}
                    </a>
                  ) : (
                    <>{o.name}</>
                  )}
                </td>
                <td className="text-center">{o.units}</td>
                <td className="text-center">
                  {numberFormatter(o.project_cost)}
                </td>
                <td className="text-center">
                  {numberFormatter(o.project_debt)}
                </td>
                <td className="text-center">
                  {numberFormatter(o.project_equity)}
                </td>
                <td className="text-center">
                  {numberFormatter(o.dollar_per_unit, 0)}
                </td>
                <td className="text-center">{o.project_type}</td>
                <td className="text-center">{o.no_of_buildings}</td>
                {/* <td className="text-start">{`${o.city},${o.state}`}</td> */}
                <td className="text-start">
                  {o.google_pin_link ? (
                    <a
                      href={o.google_pin_link}
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {`${o.city},${o.state}`}
                    </a>
                  ) : (
                    <>{`${o.city},${o.state}`}</>
                  )}
                </td>
              </tr>
            ))}
            <tr className="custom-bg-footer">
              <th></th>
              <th>
                <>Phase Totals</>
              </th>
              <th>{numberFormatterOnly(totals.units)}</th>
              <th className="text-center">
                {numberFormatter(totals.project_cost)}
              </th>
              <th className="text-center">
                {numberFormatter(totals.project_debt)}
              </th>
              <th className="text-center">
                {numberFormatter(totals.project_equity)}
              </th>
              <th className="text-center">
                {numberFormatter(totals.dollar_per_unit, 0)}
              </th>
              <th className="text-center">Mixed Variety</th>
              <th className="text-center">{totals.buildings}</th>
              <th>Multiple TX</th>
              {/* <th>
                  {deck_link ? (
                    <a href={deck_link} target="_blank">
                      {deck_name}
                    </a>
                  ) : (
                    <>{deck_name}</>
                  )}
                </th> */}
            </tr>
          </tbody>
        </table>
      )}

      <Modal show={show} onHide={() => setShow(false)} size={"lg"}>
        <ModalHeader closeButton>
          <div className="container-fluid">
            <h5 className="mb-0 w-100">{prop?.name}</h5>
            <small className="">{prop?.address}</small>
          </div>
        </ModalHeader>
        <ModalBody>
          <ProjectImagesList docsIds={prop?.property_image} />

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div className="card-like">
                  <label>Project Cost</label>
                  <div className="value">
                    {numberFormatter(prop?.project_cost)}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Project Equity</label>
                  <div className="value">
                    {numberFormatter(prop?.project_equity)}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Project Debt</label>
                  <div className="value">
                    {numberFormatter(prop?.project_debt)}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Project Type</label>
                  <div className="value">{prop?.project_type}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Units</label>
                  <div className="value">{prop?.units}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Acreage</label>
                  <div className="value">
                    {prop?.acreage ? prop?.acreage : "TBA"}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Target IRR</label>
                  <div className="value">
                    {prop?.targeted_investor_irr
                      ? prop?.targeted_investor_irr
                      : "TBA"}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Target Equity</label>
                  <div className="value">
                    {prop?.targeted_equity_multiple
                      ? prop?.targeted_equity_multiple
                      : "TBA"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const ProjectsTotalTable = ({ onlyName = false, projects = [] }) => {
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [prop, setProp] = useState();

  const [totals, setTotals] = useState({
    units: 0,
    project_cost: 0,
    project_debt: 0,
    project_equity: 0,
    dollar_per_unit: 0,
    buildings: 0,
  });

  const getProjectList = async () => {
    try {
      const result = await getMultiProperty(projects);
      setList(result.data.response);
      getCalc(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: index.js:159 ~ getProjectList ~ error", error);
    }
  };

  const getCalc = (res_list) => {
    var _target_equity = 0,
      _target_portfolio = 0,
      _units = 0,
      _project_cost = 0,
      _project_debt = 0,
      _project_equity = 0,
      _dollar_per_unit = 0,
      _buildings = 0;
    for (var x = 0; x < res_list.length; x++) {
      _target_portfolio += res_list[x].project_cost;
      _target_equity += res_list[x].project_equity;

      _units += res_list[x].units;
      _project_cost += res_list[x].project_cost;
      _project_debt += res_list[x].project_debt;
      _project_equity += res_list[x].project_equity;
      _dollar_per_unit += res_list[x].dollar_per_unit;
      _buildings += res_list[x].no_of_buildings;
    }

    setTotals({
      units: _units,
      project_cost: _project_cost,
      project_debt: _project_debt,
      project_equity: _project_equity,
      dollar_per_unit: _dollar_per_unit / res_list.length,
      buildings: _buildings,
    });
  };

  useEffect(() => {
    projects.length > 0 && getProjectList();
  }, [projects]);

  return (
    <>
      <tr className="custom-bg-footer">
        <th>
          <>Phase Totals</>
        </th>
        <th className="text-center">{numberFormatterOnly(totals.units)}</th>
        <th className="text-center">{numberFormatter(totals.project_cost)}</th>
        <th className="text-center">{numberFormatter(totals.project_debt)}</th>
        <th className="text-center">
          {numberFormatter(totals.project_equity)}
        </th>
        <th className="text-center">
          {numberFormatter(totals.dollar_per_unit, 0)}
        </th>
        <th className="text-center">Mixed Variety</th>
        <th className="text-center">{totals.buildings}</th>
        <th>Multiple TX</th>
        {/* <th>
                  {deck_link ? (
                    <a href={deck_link} target="_blank">
                      {deck_name}
                    </a>
                  ) : (
                    <>{deck_name}</>
                  )}
                </th> */}
      </tr>

      <Modal show={show} onHide={() => setShow(false)} size={"lg"}>
        <ModalHeader closeButton>
          <div className="container-fluid">
            <h5 className="mb-0 w-100">{prop?.name}</h5>
            <small className="">{prop?.address}</small>
          </div>
        </ModalHeader>
        <ModalBody>
          <ProjectImagesList docsIds={prop?.property_image} />

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div className="card-like">
                  <label>Project Cost</label>
                  <div className="value">
                    {numberFormatter(prop?.project_cost)}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Project Equity</label>
                  <div className="value">
                    {numberFormatter(prop?.project_equity)}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Project Debt</label>
                  <div className="value">
                    {numberFormatter(prop?.project_debt)}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Project Type</label>
                  <div className="value">{prop?.project_type}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Units</label>
                  <div className="value">{prop?.units}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Acreage</label>
                  <div className="value">
                    {prop?.acreage ? prop?.acreage : "TBA"}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Target IRR</label>
                  <div className="value">
                    {prop?.targeted_investor_irr
                      ? prop?.targeted_investor_irr
                      : "TBA"}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Target Equity</label>
                  <div className="value">
                    {prop?.targeted_equity_multiple
                      ? prop?.targeted_equity_multiple
                      : "TBA"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const ProjectImagesList = ({ docsIds = [] }) => {
  return (
    <Carousel className="mb-3" interval={3000} fade>
      {docsIds.map((obj) => (
        <Carousel.Item>
          <SingleImage docIds={[obj]} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

function TriggerExample({ component, msg }) {
  const renderTooltip = (props) => <Tooltip {...props}>{msg}</Tooltip>;

  return (
    <OverlayTrigger placement="top" overlay={renderTooltip}>
      {component}
    </OverlayTrigger>
  );
}

export default NewOfferPropertyHUD;
