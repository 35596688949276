import "./style.scss";
import Header from "../header";
import Footer from "../footer";
import Author from "../../images/profile.jpg";
import InputMask from "react-input-mask";
import Select from "react-select";
import validator from "validator";
import toast from "react-hot-toast";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import InputPhone from "../../components/input-phone";
import countries from "../../utils/countries.json";
import {
  FaUserAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaLock,
  FaFileInvoiceDollar,
  FaHeart,
} from "react-icons/fa";
import { responseToastMsg } from "../../utils/response-message";
import {
  getInvestorAccountsProfile,
  updateInvestorAccountsProfile,
} from "../../client/methods";
import { investorSchema } from "../../utils/schema";
import { crispStyle } from "../../utils/common";
import ChangePassword from "./change-password";
import InvestorInterest from "./investor-interest";

const Profile = ({ showProfilePage }) => {
  console.log("dddd");
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.data);
  const [show, setShow] = useState();

  useEffect(() => {
    setShow(showProfilePage);
  }, [showProfilePage]);

  const init = {
    first_name: "",
    last_name: "",
    email: "",
    ssn: "",
    self_accredited: false,
    phone_number: "",
    country_code: "",

    personal_address: {
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      country: "USA",
      postal_code: "",
    },
  };

  const AccredateOptions = [
    { label: "YES", value: true },
    { label: "NO", value: false },
  ];

  const investorProfileDetails = async (value) => {
    try {
      const result = await getInvestorAccountsProfile(value);
      formik.setValues({ ...result.data.response });
    } catch (error) {
      console.log(
        "🚀 ~ file: index.js:29 ~ investorProfileDetails ~ error",
        error
      );
    }
  };

  useEffect(() => {
    investorProfileDetails(user.token);
  }, []);

  const onSubmit = async (values) => {
    const { first_name, last_name, email } = values;

    if (first_name && last_name && email) {
      if (!validator.isEmail(email)) {
        toast.error("Enter a valid email id");
        return false;
      }

      try {
        const result = await updateInvestorAccountsProfile(
          user.user_id,
          values
        );
        responseToastMsg(result.data.response_code);
      } catch (error) {
        responseToastMsg(error.data.error_code);
        console.log(error);
      }
    }
  };

  const formik = useFormik({
    initialValues: init,
    validationSchema: investorSchema,
    onSubmit,
  });

  return (
    <>
      <Header />
      <div className="profile_wrapper">
        <div className="container profile_inr">
          <div className="row">
            <div className="col-md-3">
              <div className="sidebar">
                <div className="profile_head">
                  <div className="s_1">
                    <img src={Author} />
                  </div>
                  <h4>{`${formik.values.first_name} ${formik.values.last_name}`}</h4>
                  {/* <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <h5>$768000</h5>
                          <h4>
                            Total <br /> Investment
                          </h4>
                        </td>
                        <td>
                          <h5>24</h5>
                          <h4>
                            Invested <br /> Properties
                          </h4>
                        </td>
                      </tr>
                    </tbody>
                  </table> */}
                </div>

                <div className="side_menus">
                  <ul>
                    <li role="button" onClick={() => navigate("/profile")}>
                      <FaUserAlt /> Profile
                    </li>{" "}
                    <li
                      className="mt-4"
                      role="button"
                      onClick={() => navigate("/profile/interest")}
                    >
                      <FaHeart /> My Interest
                    </li>
                    <li
                      className="mt-4"
                      role="button"
                      onClick={() => navigate("/profile/change-password")}
                    >
                      <FaLock /> Change Password
                    </li>
                    {/* <li>
                      <Link to="/properties">
                        {" "}
                        <FaFileInvoiceDollar /> Investing Preferences
                      </Link>
                    </li>
                    <li>
                      <Link to="/properties">
                        {" "}
                        <FaPhoneAlt /> Phone Preferences
                      </Link>
                    </li>
                    <li>
                      <Link to="/properties">
                        {" "}
                        <FaEnvelope /> Email Preferences
                      </Link>
                    </li>
                    <li>
                      <Link to="/properties">
                        {" "}
                        <FaLock /> Privacy & Sharing
                      </Link>
                    </li>
                    <li>
                      <Link to="/properties">
                        {" "}
                        <FaFileInvoiceDollar /> Investing Preferences
                      </Link>
                    </li>
                    <li>
                      <Link to="/properties">
                        {" "}
                        <FaPhoneAlt /> Phone Preferences
                      </Link>
                    </li>
                    <li>
                      <Link to="/properties">
                        {" "}
                        <FaEnvelope /> Email Preferences
                      </Link>
                    </li>
                    <li>
                      <Link to="/properties">
                        {" "}
                        <FaLock /> Privacy & Sharing
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-9">
              {(() => {
                switch (showProfilePage) {
                  case "change_password_page":
                    return <ChangePassword user={user} />;

                  case "interest_page":
                    return <InvestorInterest />;

                  default:
                    return (
                      <form onSubmit={formik.handleSubmit}>
                        <div className="s_detailed">
                          <h1>Profile</h1>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="box_1">
                                <h3>Personal Information</h3>
                                <div className="row">
                                  <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="form-group">
                                      <label>First Name</label>
                                      <input
                                        type="text"
                                        placeholder="First Name"
                                        size="sm"
                                        value={formik.values.first_name}
                                        name="first_name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className={`form-control ${
                                          formik.touched.first_name &&
                                          (formik.errors.first_name
                                            ? "is-invalid"
                                            : !formik.errors.first_name &&
                                              "is-valid")
                                        }`}
                                      />
                                      {formik.errors.first_name &&
                                        formik.touched.first_name && (
                                          <p className="text-danger">
                                            {formik.errors.first_name}
                                          </p>
                                        )}
                                    </div>
                                  </div>

                                  <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="form-group">
                                      <label>Middle Name</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Middle Name"
                                        size="sm"
                                        value={formik.values.middle_name}
                                        name="middle_name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="form-group">
                                      <label>Last Name</label>
                                      <input
                                        type="text"
                                        placeholder="Last Name"
                                        size="sm"
                                        value={formik.values.last_name}
                                        name="last_name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className={`form-control ${
                                          formik.touched.last_name &&
                                          (formik.errors.last_name
                                            ? "is-invalid"
                                            : !formik.errors.last_name &&
                                              "is-valid")
                                        }`}
                                      />
                                      {formik.errors.last_name &&
                                        formik.touched.last_name && (
                                          <p className="text-danger">
                                            {formik.errors.last_name}
                                          </p>
                                        )}
                                    </div>
                                  </div>

                                  <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="form-group">
                                      <label>SSN*</label>
                                      <InputMask
                                        className="form-control"
                                        mask="999-99-9999"
                                        maskChar="_"
                                        value={formik.values.ssn}
                                        name="ssn"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />

                                      {formik.errors.email &&
                                        formik.touched.email && (
                                          <p className="text-danger">
                                            {formik.errors.email}
                                          </p>
                                        )}
                                    </div>
                                  </div>

                                  {/* <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="form-group">
                                      <label>Accredited Investor</label>
                                      <Select
                                        menuPosition={"fixed"}
                                        value={
                                          formik.values?.self_accredited
                                            ? AccredateOptions.find(
                                                (o) =>
                                                  o.value ===
                                                  formik.values.self_accredited
                                              )
                                            : null
                                        }
                                        styles={crispStyle}
                                        options={AccredateOptions}
                                        onChange={async (data) =>
                                          await formik.setFieldValue(
                                            "self_accredited"
                                          )(data.value)
                                        }
                                      />
                                    </div>
                                  </div> */}

                                  <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="form-group">
                                      <label>Email*</label>
                                      <input
                                        type="email"
                                        placeholder="Email"
                                        size="sm"
                                        value={formik.values.email}
                                        name="email"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className={`form-control ${
                                          formik.touched.email &&
                                          (formik.errors.email
                                            ? "is-invalid"
                                            : !formik.errors.email &&
                                              "is-valid")
                                        }`}
                                      />
                                      {formik.errors.email &&
                                        formik.touched.email && (
                                          <p className="text-danger">
                                            {formik.errors.email}
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="form-group">
                                      <label>Mobile Number</label>
                                      <InputPhone
                                        className="form-control"
                                        defaultCountry={"us"}
                                        value={formik.values.phone_number}
                                        onChange={(e, c_code) => {
                                          formik.handleChange("phone_number")(
                                            e
                                          );
                                          formik.handleChange("country_code")(
                                            c_code.countryCode.toUpperCase()
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="box_1">
                                <h3>Personal Address</h3>
                                <div className="row">
                                  <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="form-group">
                                      <label>Address Line 1*</label>
                                      <input
                                        type="text"
                                        placeholder="Address Line 1"
                                        size="sm"
                                        value={
                                          formik?.values?.personal_address
                                            ?.address_line_1
                                        }
                                        name="personal_address.address_line_1"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className={`form-control ${
                                          formik.touched.address_line_1 &&
                                          (formik.errors.address_line_1
                                            ? "is-invalid"
                                            : !formik.errors.address_line_1 &&
                                              "is-valid")
                                        }`}
                                      />
                                      {formik.errors.address_line_1 &&
                                        formik.touched.address_line_1 && (
                                          <p className="text-danger">
                                            {formik.errors.address_line_1}
                                          </p>
                                        )}
                                    </div>
                                  </div>

                                  <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="form-group">
                                      <label>Address Line 2*</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Address Line 2"
                                        size="sm"
                                        value={
                                          formik?.values?.personal_address
                                            ?.address_line_2
                                        }
                                        name="personal_address.address_line_2"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="form-group">
                                      <label>City</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="City"
                                        size="sm"
                                        value={
                                          formik?.values?.personal_address?.city
                                        }
                                        name="personal_address.city"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="form-group">
                                      <label>State</label>
                                      <input
                                        className="form-control"
                                        type="state"
                                        placeholder="state"
                                        size="sm"
                                        value={
                                          formik?.values?.personal_address
                                            ?.state
                                        }
                                        name="personal_address.state"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="form-group">
                                      <label>Country</label>
                                      <Select
                                        menuPosition={"fixed"}
                                        value={
                                          formik.values?.employer_information
                                            ?.address?.country
                                            ? countries
                                                .map((o) => ({
                                                  label: o.name,
                                                  value: o.code3,
                                                }))
                                                .find(
                                                  (o) =>
                                                    o.value ===
                                                    formik.values
                                                      ?.employer_information
                                                      ?.address?.country
                                                )
                                            : null
                                        }
                                        //   styles={crispStyle}
                                        options={countries.map((o) => ({
                                          label: o.name,
                                          value: o.code3,
                                        }))}
                                        onChange={(data) =>
                                          formik.handleChange(
                                            "employer_information.address.country"
                                          )(data.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12 text-center mt-3">
                                <button className="btn btn-primary">
                                  Save Changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    );
                }
              })()}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;
