import Header from "../../header";
import Footer from "../../footer";

import { Link, useLocation, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { loginApi } from "../../../client/methods";
import { responseToastMsg } from "../../../utils/response-message";
import { useFormik } from "formik";
import { loginSchema } from "../../../utils/schema";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../../images/logo.png";
import {
  userLoginThunk,
  userLogoutThunk,
} from "../../../redux/thunk/user.thunk";
import { useQuery } from "../../../hooks/url-params";
import { getCookies } from "../../../utils/cookies";
import AppPasswordInput from "../../../components/app-password-input";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const query = useQuery(location.search);
  const redirect = query.get("redirect");

  const init = {
    email: "",
    password: "",
  };

  useEffect(() => {
    if (user.login && getCookies()) {
      if (user.data.is_reset_password) {
        navigate("/create-password");
      } else {
        // navigate("/profile");

        if (redirect) {
          window.open(redirect, "_self");
        } else {
          if (location.state?.from) {
            navigate(location.state?.from.pathname);
          } else {
            navigate("/");
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.login, navigate, location.state?.from, redirect]);

  useEffect(() => {
    if (!(user.login && getCookies())) {
      dispatch(userLogoutThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values) => {
    try {
      dispatch(userLoginThunk(values));
    } catch (error) {
      responseToastMsg(error.data.error_code);
      console.log("🚀 ~ file: index.js ~ line 59 ~ handleSave ~ error", error);
    }
  };

  const formik = useFormik({
    initialValues: init,
    validationSchema: loginSchema,
    onSubmit,
  });

  return (
    <>
      {/* <Header page={"signin"} /> */}
      <div className="login_wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="l_inner">
                <div className="text-center mb-4">
                  <img
                    style={{
                      background: "#0c3c60",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                    src={Logo}
                    alt=""
                    width={130}
                    onClick={() => navigate("/")}
                  />
                </div>
                <h2>Sign In - Alamo Multifamily Fund</h2>
                <p class="sub-title"></p>
                <form onSubmit={formik.handleSubmit}>
                  <div className="form-group mb-4">
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="john@domain.com"
                      value={formik.values.email}
                      name="email"
                      onChange={formik.handleChange}
                      className={`form-control ${
                        formik.touched.email &&
                        (formik.errors.email
                          ? "is-invalid"
                          : !formik.errors.email && "is-valid")
                      }`}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <p className="text-danger">{formik.errors.email}</p>
                    )}
                  </div>
                  <div className="form-group mb-4">
                    <label>Password</label>
                    <AppPasswordInput
                      placeholder="Password"
                      value={formik.values.password}
                      name="password"
                      onChange={formik.handleChange}
                      className={`form-control ${
                        formik.touched.password &&
                        (formik.errors.password
                          ? "is-invalid"
                          : !formik.errors.password && "is-valid")
                      }`}
                    />

                    {formik.errors.password && formik.touched.password && (
                      <p className="text-danger">{formik.errors.password}</p>
                    )}
                  </div>

                  <div className="text-center">
                    <button className="btn btn-primary" type="submit">
                      Sign In
                    </button>
                  </div>
                </form>
                <p className="mt-4 text-center">
                  <Link to="/forgot-password">Forgot password? </Link>
                </p>
                <p className="mt-4 text-center">
                  {" "}
                  Don't have an account?{" "}
                  <Link to="/signup?type=general">
                    <u>Sign Up</u>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
