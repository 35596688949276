import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./style.scss";

const AppPasswordInput = (props) => {
  const [password, setPassword] = useState(true);
  return (
    <div className="password-group">
      <input type={password ? "password" : "text"} {...props} />
      <span className="eye-icon">
        {!password ? (
          <FaEye
            role="button"
            onClick={() => setPassword(!password)}
            className="eye"
          />
        ) : (
          <FaEyeSlash
            className="eye"
            role="button"
            onClick={() => setPassword(!password)}
          />
        )}
      </span>
    </div>
  );
};

export default AppPasswordInput;
