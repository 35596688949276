import React from "react";
import { useState, useEffect } from "react";
import { dateFormat, list_size } from "../../utils/common";
import { getInvestorInterest } from "../../client/methods";
import { useSelector } from "react-redux";
import {
  numberFormatterOnly,
  investment_next_options,
  investment_now_options,
} from "../../utils/common";

const InvestorInterest = () => {
  const [count, setCount] = useState(0);
  const [list, setList] = useState([]);
  const [loadHide, setLoadHide] = useState(false);

  const investor_id = useSelector((state) => state.user.data.user_id);

  useEffect(() => {
    getList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const investmentNowOptions = (ivestmentNow) => {
    return investment_now_options.map((o) => {
      if (o.value == ivestmentNow) {
        return o.label;
      }
    });
  };

  const investmentNextOptions = (investmentNext) => {
    return investment_next_options.map((o) => {
      if (o.value == investmentNext) {
        return `${o.label} (in 12 months)`;
      }
    });
  };

  const getList = async () => {
    try {
      const result = await getInvestorInterest(list_size, count, investor_id);

      setList([...list, ...result.data.response]);
      setCount(count + result.data.response.length);
      if (result.data.response.length === 0) {
        setLoadHide(true);
      }
    } catch (error) {
      //   responseToastMsg(error.data.error_code);
    }
  };

  return (
    <div>
      <div className="s_detailed">
        <h1>My Interest</h1>
        <div className="box_1">
          <div className="content-view">
            <div className="table-responsive">
              {list.length > 0 ? (
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th> Title </th>
                      <th> Amount </th>
                      <th> Interested On </th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((obj) => (
                      <tr>
                        <td>{obj?.listing_name?.title}</td>
                        <td>
                          {obj?.sign_up_type === "invest" ? (
                            <>
                              <p>
                                {`$${numberFormatterOnly(
                                  obj?.expected_investment_now_value_non_ira
                                )}(IRA)`}
                              </p>
                              <p>
                                {`$${numberFormatterOnly(
                                  obj?.expected_investment_now_value
                                )}(NON-IRA)`}
                              </p>
                            </>
                          ) : (
                            <>
                              <p>
                                {obj.expected_investment_now
                                  ? investmentNowOptions(
                                      obj?.expected_investment_now
                                    )
                                  : null}
                              </p>
                              <p>
                                {obj.expected_investment_next
                                  ? investmentNextOptions(
                                      obj?.expected_investment_next
                                    )
                                  : null}
                              </p>
                            </>
                          )}
                        </td>
                        <td>{dateFormat(obj.createdAt)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <>
                  <div className="text-center">No Interest found so far</div>
                </>
              )}
              {!loadHide && (
                <div className="text-center mt-2">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={() => getList()}
                  >
                    Load more
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorInterest;
